import React from "react"

import Layout from "../components/layout"
import SectionLayout from "../components/SectionLayout"

const NotFoundPage = () => (
  <Layout>
    <SectionLayout style={{ height: "400px" }}>
      <h1>Thank you for your message!</h1>
    </SectionLayout>
  </Layout>
)

export default NotFoundPage
